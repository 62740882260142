// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { AppLink } from '@finect/tabular-core/AppLink';
import { Button } from '@finect/tabular-core/Button';
import { TextEllipsis } from '@finect/tabular-components/TextEllipsis';
import { AssetsLabel } from '@finect/tabular-components/Labels';
import { getProductAction } from '@finect/front-resources/Products';
import { bindActionCreators } from 'redux';
import { setProductRequest } from '../../../../../../store/products/actions';

import {
  CourseName,
  CourseInfo,
  CourseOwner,
  CoursePrice,
  CourseDescription,
  CourseImage,
  CourseImageContainer,
  CourseContainer,
  CourseAction,
  CourseFeatures,
  CourseOriginalPrice
} from './partials';

type Props = {
  contentPiece: Object,
  actions: Object
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setProductRequest
    },
    dispatch
  )
});

export class Courses extends React.Component<Props> {
  // eslint-disable-next-line class-methods-use-this
  getPrice = (course: Object) => {
    const priceOriginalMetadata = course.entity.metadata.find(item => item.meta === 'price_original');

    const priceMetadata = course.entity.metadata.find(item => item.meta === 'price');

    return {
      price: priceMetadata ? Number(priceMetadata.data) : 0,
      original: priceOriginalMetadata ? Number(priceOriginalMetadata.data) : 0
    };
  };

  handleProductRequest = (product: Object) => {
    const { actions } = this.props;
    actions.setProductRequest(product);
  };

  render() {
    const { contentPiece } = this.props;
    const courses = contentPiece.model;
    if (!courses || !courses.length) {
      return null;
    }

    return (
      <Fragment>
        {courses.map(course => (
          <CourseContainer key={course.id}>
            <CourseImageContainer>
              <CourseImage src={course.entity.image} />
            </CourseImageContainer>
            <CourseInfo>
              <CourseName>
                <CourseImage src={course.entity.image} />
                <AppLink href={`/cursos/${course.entity.alias}`}>{course.entity.name}</AppLink>
              </CourseName>
              <CourseDescription>
                <TextEllipsis lines={3}>{course.entity.description}</TextEllipsis>
              </CourseDescription>
              <CourseFeatures>
                <CoursePrice>
                  {this.getPrice(course).original ? (
                    <CourseOriginalPrice>
                      <AssetsLabel value={this.getPrice(course).original} decimals={0} />
                    </CourseOriginalPrice>
                  ) :
                    ''
                  }
                  {this.getPrice(course).price ? (
                    <Fragment>
                      <AssetsLabel value={this.getPrice(course).price} decimals={0} /> IVA inc.
                    </Fragment>
                  ) :
                    'Gratuito'
                  }
                </CoursePrice>
                <CourseOwner>
                  Impartido por:{' '}
                  {course.entity.commercializations &&
                    course.entity.commercializations.length > 0 &&
                    course.entity.commercializations[0].organization.displayName}
                </CourseOwner>
              </CourseFeatures>
              <CourseAction>
                <Button
                  onClick={() => {
                    this.handleProductRequest(course);
                  }}
                  width="200px"
                  secondary
                >
                  {getProductAction(course).action}
                </Button>
              </CourseAction>
            </CourseInfo>
          </CourseContainer>
        ))}
      </Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(Courses);
