// @flow
import React from 'react';
import { AppLink } from '@finect/tabular-core/AppLink';
import { Image as TabularImage } from '@finect/tabular-components/Image';
import { WrapperHTMLPieces } from './../../partials';

type Props = {
  content: Object
};

/**
 * Muestra una imagen
 */
export const Image = ({ content }: Props) => {
  const withLink = Boolean(content.url.destination);

  // TODO: Se está usando AppLink para elementos que no son enlaces
  return (
    <WrapperHTMLPieces>
      <AppLink {...(withLink ? { href: content.url.destination } : {})}>
        <TabularImage src={content.url.source} />
      </AppLink>
    </WrapperHTMLPieces>
  );
};
