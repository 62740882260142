// @flow
/* eslint-disable no-inline-comments */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import { getArticlePath as getArticlePathTabular } from '@finect/tabular-components/Articles';
import { nl2br, addNoFollowsToExternal, linkify } from './html';

export const getArticlePath = (article: Object) => getArticlePathTabular(article);

export const getArticleURL = (article: Object) => {
  const path = getArticlePath(article);

  return `https://${process.env.FINECT_BASEURL || 'www.finect.com'}/${path.startsWith('/') ? path.substr(1) : path}`;
};

export const getArticleEditionPath = (article: Object) => {
  // TODO: Cambiar alias por id cuando se haga el cambio de PHP a React
  if (article.author.subtype === 'user') {
    return `usuario/${article.author.alias}/articulos/${article.id}/editar`;
  } else if (article.author.subtype === 'company') {
    return `grupos/${article.author.alias}/articulos/${article.id}`;
  }

  return '#';
};

export const getArticleDescription = (html: string) => {
  const description = html.replace(/<[^>]*>?/g, '');

  return description.length >= 120 ? `${description.substr(0, 119)} ...` : description;
};

export const getArticlePreviewText = (html: string) => {
  // eliminar los pies de foto de las imágenes en figcaption
  const previewWithoutCaptions = html.replace(/<figcaption>[\s\S]*?<\/figcaption>/g, '');

  // parse special character &amp; to &
  const previewParsed = previewWithoutCaptions.replace(/&amp;/g, '&');

  const preview = previewParsed.replace(/<[^>]*>?/g, '');

  // sustituir caracteres especiales
  const previewClean = preview
    .replace(/&nbsp;/g, ' ')
    .replace(/&aacute;/g, 'á')
    .replace(/&eacute;/g, 'é')
    .replace(/&iacute;/g, 'í')
    .replace(/&oacute;/g, 'ó')
    .replace(/&uacute;/g, 'ú')
    .replace(/&ntilde;/g, 'ñ')
    .replace(/&Aacute;/g, 'Á')
    .replace(/&Eacute;/g, 'É')
    .replace(/&Iacute;/g, 'Í')
    .replace(/&Oacute;/g, 'Ó')
    .replace(/&Uacute;/g, 'Ú')
    .replace(/&Ntilde;/g, 'Ñ');

  return previewClean.length >= 280 ? `${previewClean.substr(0, 279)} ...` : previewClean;
};

const ETHICS_POLICY_URL = 'https://www.finect.com/codigo-etico';

export const getSchemaORG = (article: Object, metaDescription: string) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Article',
    headline: `${article.title}`,
    description: metaDescription,
    publisher: {
      '@type': 'Organization',
      name: 'Finect',
      logo: {
        '@type': 'ImageObject',
        url: 'https://static.finect.com/img/logo/finect-logo-600x60.png',
        width: 600,
        height: 60
      },
      diversityPolicy: `${ETHICS_POLICY_URL}#diversidad`,
      masthead: `${ETHICS_POLICY_URL}#estructura`,
      ethicsPolicy: `${ETHICS_POLICY_URL}#valores`,
      noBylinesPolicy: `${ETHICS_POLICY_URL}`,
      sameAs: [
        'https://twitter.com/finect',
        'https://facebook.com/finect',
        'https://instagram.com/finect/',
        'https://www.linkedin.com/company/finect/',
        'https://www.youtube.com/finect'
      ]
    },
    mainEntityOfPage: `https:/www.finect.com/${article.author.subtype === 'user' ? 'usuario' : 'grupos'}/${
      article.author.alias
    }`,
    datePublished: `${article.revision.created}`,
    dateModified: `${article.revision.updated}`,
    author: {
      '@type': `${article.author.subtype === 'user' ? 'Person' : 'Organization'}`,
      name: `${article.author.displayName}`
    },
    image: {
      '@type': 'ImageObject',
      url: `${article.image}`,
      width: '1200',
      height: '800'
    }
  };

  return JSON.stringify(schema);
};

export const getCommentHTML = (comment: string) => {
  const MENTIONED_USERS_REGEX = /(?!<a[^>]*?>)(@[a-zA-Z0-9_\-\.]{3,19}[a-zA-Z0-9_\-])(?![^<]*?<\/a>)/g;

  let mentionedUsers = comment.match(MENTIONED_USERS_REGEX);
  mentionedUsers = mentionedUsers ? mentionedUsers.filter((elem, index, self) => index == self.indexOf(elem)) : [];

  let newComment = comment;

  for (let i = 0; i < mentionedUsers.length; i++) {
    const userName = mentionedUsers[i].substr(1, mentionedUsers[i].length);

    const link = `<a href="https://www.finect.com/usuario/${userName}">@${userName}</a>`;
    newComment = newComment.replace(new RegExp(`@${userName}`, 'g'), link);
  }

  return addNoFollowsToExternal(linkify(nl2br(newComment)));
};

export const getTitleShare = (title: string) => title.replace('|', '-');
