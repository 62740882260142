// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { getProductTypeById } from '@finect/front-resources/Products';
import { setError } from './../error/actions';
import { models } from './../../utils/models';

const expands = {
  collectives: `classes,commercializations,stats/performance,stats/standardDeviation,stats/alpha,stats/beta,stats/standardDeviation,stats/maxDrawdown,breakdown,stats/sharpeRatio,stats/correlation,stats/r2,stats/trackingError,stats/informationRatio,comparer`,
  generics: null
};

export function addFund(fund: Object) {
  return {
    type: 'COMPARISON_ADD_FUND',
    fund
  };
}

export function clearFunds() {
  return {
    type: 'COMPARISON_CLEAR_FUNDS'
  };
}

export function getFund(productType: Object, isin: string) {
  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: `products/collectives/${productType.api}/${isin}?expand=${expands.collectives}`,
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => {
        dispatch(addFund(models.investmentProducts.product(productType, data)));
      },
      onError: () => dispatch(setError({ code: 404, message: 'Fondo no encontrado' }))
    });
}

export function setFunds(funds: Array<Object>) {
  return {
    type: 'COMPARISON_SET_FUNDS',
    funds
  };
}

export function fetchFunds(productType: Object | null, funds: Array<string>) {
  return (dispatch: Dispatch) =>
    Promise.all(
      funds.map(isin =>
        finectAPI({
          version: 'v4',
          path: `products/collectives/${productType ? productType.api : 'funds'}/${isin}${
            productType ? `?expand=${expands.collectives}` : ''
          }`,
          method: 'GET',
          onSuccess: ({ data }: { data: Object }) => {
            if (productType) {
              return models.investmentProducts.product(productType, data);
            }

            // fetch funds with id en path 'products/ES0114678034'
            return finectAPI({
              version: 'v4',
              path: `products/${data.id}?expand=${expands.collectives}`,
              method: 'GET',
              onSuccess: ({ data: data2 }: { data: Object }) => {
                const itemProductType = getProductTypeById(data2.subtype);
                return models.investmentProducts.product(itemProductType, data2.entity);
              },
              onError: () => null
            });
          },
          onError: () => null
        }))
    ).then(response => dispatch(setFunds(response.filter(fund => fund))));
}

export function openDialog() {
  return {
    type: 'COMPARISON_OPEN_DIALOG',
    index: 1
  };
}

export function closeDialog() {
  return {
    type: 'COMPARISON_CLOSE_DIALOG',
    index: 1
  };
}

export function removeProduct(product: Object) {
  return {
    type: 'COMPARISON_REMOVE_PRODUCT',
    product
  };
}

export function setUserComparisons(sessions: Array<Object>) {
  return {
    type: 'COMPARISON_SET_USER_COMPARISONS',
    sessions
  };
}

export function setUserComparisonsFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'COMPARISON_SET_USER_COMPARISONS_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function setUserComparisonsDialog(open: boolean) {
  return {
    type: 'COMPARISON_SET_USER_COMPARISONS_DIALOG',
    open
  };
}

// thunks
export function fetchUserComparisons(cookies?: string) {
  return (dispatch: Dispatch) =>
    Promise.all([dispatch(setUserComparisonsFetchingStatus(true, true))]).then(() =>
      finectAPI({
        version: 'v4',
        path: `applications/products-comparison/sessions`,
        method: 'GET',
        credentials: cookies,
        onSuccess: ({ data }: { data: Array<Object> }) =>
          dispatch(
            setUserComparisons(
              data.sort((sessionA, sessionB) => new Date(sessionB.created) - new Date(sessionA.created))
            )
          )
      }));
}

// Initial Data
export function fetchInitialData(cookies?: string, productType: Object | null, funds: Array<string>) {
  return (dispatch: Dispatch) => {
    const promises = [dispatch(fetchUserComparisons(cookies))];
    funds.length && promises.push(dispatch(fetchFunds(productType, funds)));

    return Promise.all(promises);
  };
}

// Products

export function setProducts(products: Array<Object>) {
  return {
    type: 'COMPARISON_SET_PRODUCTS',
    products
  };
}

export function fetchProducts(productType: Object, productsIds: Array<string>) {
  const family = productType.category === 'iic' ? 'collectives' : 'generics';

  const expand = expands[family] || null;

  return (dispatch: Dispatch) => {
    const promises = productsIds.map(id =>
      finectAPI({
        version: 'v4',
        path: `products/${family}/${productType.api}/${id}${expand ? `?expand=${expand}` : ''}`,
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) => data,
        onError: () => null
      }));

    return Promise.all(promises).then(response =>
      dispatch(setProducts(response.map(product => ({ subtype: productType.id, entity: product })))));
  };
}

// **************************************************************************************************************+
// Drawer comparison

export function setDrawerProductSelected(product: Object) {
  return {
    type: 'DRAWER_COMPARISON_SET_PRODUCT',
    product
  };
}

export function setDrawerEmpty() {
  return {
    type: 'DRAWER_COMPARISON_SET_EMPTY'
  };
}

/**
 * Análisis
 *
 */
export function setAnalysis(analysis: string) {
  return {
    type: 'SET_ANALYSIS',
    analysis
  };
}

export function setAnalysisError(analysisError: string) {
  return {
    type: 'SET_ANALYSIS_ERROR',
    analysisError
  };
}

export function setLoadingAnalysis(loadingAnalysis: boolean) {
  return {
    type: 'SET_LOADING_ANALYSIS',
    loadingAnalysis
  };
}

export function createAnalysis(uuid: string, funds: Array<Object>, type: string, productType: Object | null) {
  const app = productType && productType.api ? `${productType.api}-comparison` : 'supercomparison';
  return (dispatch: Dispatch) => {
    dispatch(setLoadingAnalysis(true));
    finectAPI({
      version: 'v4',
      path: 'products/analysis/comparison',
      method: 'POST',
      body: {
        type,
        funds,
        app
      },
      onSuccess: ({ data }: { data: Object }) => {
        dispatch(setAnalysis(`${data}
<i>Este análisis se ha elaborado con inteligencia artificial, a partir de la información disponible en las fichas de Finect. No ha sido revisado por el equipo de Finect y no constituye una recomendación ni propuesta de inversión. La inversión contiene riesgos. Las rentabilidades pasadas no son garantía de rentabilidades futuras.</i>
<b>¿Te puedo ayudar con algo más?</b>`));
        dispatch(setLoadingAnalysis(false));
      },
      onError: (error) => {
        console.log(error.status);
        if (error.status === 429) {
          dispatch(setAnalysisError('Ya has hecho trabajar mucho a SonIA por hoy 😉 Hasta la IA se cansa, vuelve mañana.'));
        } else if (error.status === 503) {
          dispatch(setAnalysisError('Hemos tenido muchísimas peticiones hoy. La IA tiene que descansar, vuelve mañana :)'));
        } else {
          dispatch(setAnalysisError('Parece que ha habido un error al generar el análsisis. Por favor, inténtalo de nuevo.\n<b>¿Te puedo ayudar con algo más?</b>'));
        }
        dispatch(setLoadingAnalysis(false));
      }
    });
  };
}
