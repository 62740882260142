// @flow
import styled, { css } from 'styled-components';
import { getFont } from '@finect/tabular-utils/getFont';
import { grid } from '@finect/tabular-utils/grid';
import { maxMediaQueries, minMediaQueries } from '@finect/tabular-utils/mediaQueries';

export const Title = styled.div`
  display: inline-block;
  padding-bottom: 15px;
  ${getFont(-1, 'compact')}
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryColor};

  ${maxMediaQueries.phablet`
    padding-right: 50px;
  `};
`;

const withIconStyles = css`
  display: flex;
  align-items: center;
  padding-bottom: 9px;
`;

const defautlStyles = css`
  padding-bottom: 6px;
`;

const activeStyles = css`
  a {
    color: ${({ theme, selected }) => (selected ? theme.mainColor : theme.borderColor)};

    &:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      opacity: ${({ selected }) => (selected ? 1 : 0)};
      width: 100%;
      height: 3px;
      border-radius: 1.5px;
      content: '';
      background-color: ${({ theme, selected }) => (selected ? theme.mainColor : theme.borderColor)};
      transform: translate(-50%, -50%);
      transition: opacity 0.15s ease;
    }
  }
`;

export const FeedItem = styled.li`
  position: relative;
  cursor: pointer;
  ${({ withIcon }) => (withIcon ? withIconStyles : defautlStyles)};
  ${({ selected }) => selected && 'font-weight: 600;'};
  color: ${({ theme }) => theme.fontColor};
  transition: color 0.15s ease;

  ${maxMediaQueries.miniDesktop`
    a {
      display: block;
      color: ${({ theme }) => theme.colors.fontL};
      padding: 6px;
    }

    ${({ selected }) => (selected ? activeStyles : '')};

  `}

  ${minMediaQueries.miniDesktop`
    a {
      color: ${({ theme }) => theme.colors.fontL};

      &:visited {
        color: ${({ theme }) => theme.colors.fontL};
      }

      &:hover {
        color: ${({ theme }) => theme.colors.font};
      }
    }

    &:before {
      position: absolute;
      top: 0;
      bottom: 8px;
      left: -12px;
      opacity: ${({ selected }) => (selected ? 1 : 0)};
      width: 3px;
      border-radius: 1.5px;
      content: '';
      background-color: ${({ theme, selected }) => (selected ? theme.mainColor : theme.borderColor)};
      transition: opacity 0.15s ease;
    }

    &:hover:before {
      opacity: 1;
    }
  `}
`;

export const FeedNavigation = styled.div`
  display: none;
  align-self: flex-start;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  border-right: 1px solid #eee;
  margin-right: ${({ theme }) => theme.columnGap}px;
  -webkit-overflow-scrolling: touch;

  ${maxMediaQueries.miniDesktop`
    display: block;
    margin-top: -16px;
    margin-bottom: 24px;

    > div {
      display: flex;
      overflow-x: scroll;

      ::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      ${Title}{
        display: none;
      }

      ${FeedItem} {
        white-space: nowrap;
      }

      ul {
        display: flex;
      }
    }

    ul {
      display: none;
    }

    button {
      display: none;
    }
  `}

  ${minMediaQueries.miniDesktop`
    display: flex;
    position: sticky;
    flex-direction: column;
    justify-content: flex-end;
    top: 30px;
    top: ${({ omninavState }) => (omninavState === 'opened' ? '135px' : '30px')};
    min-width: ${grid(2)}px;
    max-width: ${grid(2)}px;
    transition: top 0.25s cubic-bezier(0.49, 0.42, 0.52, 0.98);

    ${getFont(0, 'compact')}

    ul {
      display: block;
      padding: 0 0 15px 12px;
    }
  `};

  ul + ul {
    position: relative;

    &:before {
      position: absolute;
      top: -11px;
      width: 24px;
      border-top: 1px solid ${({ theme }) => theme.borderColor};
      content: '';
    }
  }
`;

export const MenuSection = styled.div`
  ${maxMediaQueries.miniDesktop`
    ${({ isCore }) => (isCore ? 'display: block;' : 'display:none;')}
  `}
`;
