// @flow
import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { conversations } from '@finect/tabular-conversations/store';
import { authorizations } from '@finect/front-core/Authorizations';
import { apps } from '@finect/front-core/Apps';
import { user } from '@finect/front-core/User';
import { impersonate } from '@finect/front-core/Impersonate';
import { help } from '@finect/tabular-components/Help';
import type { StoreType } from '../types/store';

import { error } from './error/reducers';
import { fund } from './fund/reducers';
import { omninav } from './omninav/reducers';
import { comparison } from './comparison/reducers';
import { questions } from './questions/reducers';
import { products } from './products/reducers';
import { content } from './content/reducers';
import { banks } from './banks/reducers';
import { terms } from './terms/reducers';
import { articles } from './articles/reducers';
import { context } from './context/reducers';
import { profile } from './profile/reducers';
import { cms } from './cms/reducers';
import { home } from './home/reducers';

const reducers: Object = {
  impersonate,
  omninav,
  user,
  error,
  fund,
  comparison,
  questions,
  apps,
  products,
  content,
  authorizations,
  banks,
  terms,
  articles,
  conversations,
  context,
  profile,
  help,
  cms,
  home
};

const reducer = combineReducers(reducers);

/* eslint-disable */
export function configureStore(initialState: Object = {}) {
  const store = createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );

  return store;
}
/* eslint-enable */

export const mapStateToProps = (state: StoreType) => state;
