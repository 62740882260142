// @flow
import React from 'react';
import { ProductsTable } from '@finect/tabular-components/Products';
import { getProductTypeById, productTypes } from '@finect/front-resources/Products';
import { Paragraph } from '@finect/tabular-core/Paragraph';
import { withComparison } from '../../../../../../HOC';
import type { WithComparisonProps } from '../../../../../../HOC';

const IIC_FIELDS = [
  { id: 'name', textAlign: 'left', withManagerIcon: false, withSPA: false },
  { id: 'performance', period: 'M0', type: 'accumulated' },
  { id: 'performance', period: 'M12', type: 'accumulated' },
  { id: 'performance', period: 'M36', type: 'annualized' }
];

const NO_IIC_FIELDS = [{ id: 'name', textAlign: 'left', withManagerIcon: false, withSPA: false }];

const FIELDS_BY_PRODUCT_TYPE = Object.values(productTypes).map(({ id, category }: Object) => ({
  productType: id,
  fields: category === 'iic' ? IIC_FIELDS : NO_IIC_FIELDS
}));

type Props = {
  contentPiece: Object
} & WithComparisonProps;

type State = {
  order: {
    type: 'asc' | 'desc' | 'none',
    field: string | null,
    ordering: 'parent' | 'child'
  } | null
};

export class TableProducts extends React.Component<Props, State> {
  state = {
    order: {
      type: 'none',
      field: null,
      ordering: 'child'
    }
  };

  handleOrderChange = (order: Object) => {
    this.setState({ order });
  };

  render() {
    const { contentPiece, comparisonActions, selectedProducts, selectedProductsType } = this.props;
    const { order } = this.state;

    if (!contentPiece.model || !contentPiece.model.length) return null;

    const productTypeId = contentPiece.model[0].subtype;
    const productType = getProductTypeById(productTypeId);

    const fieldsDefinition: Object = FIELDS_BY_PRODUCT_TYPE.find(item => item.productType === productTypeId);

    if (!fieldsDefinition) {
      return <Paragraph>La familia de producto {productTypeId} no está soportada por este componente.</Paragraph>;
    }

    return (
      <ProductsTable
        productType={productType}
        size="small"
        zebra={false}
        selectable={{ initial: true }}
        onProductSelect={comparisonActions.setDrawerProductSelected}
        selectedProducts={selectedProducts}
        selectedProductsType={selectedProductsType}
        products={contentPiece.model}
        fields={fieldsDefinition.fields}
        onOrderChange={this.handleOrderChange}
        order={order}
        productsSubscriptions={[]}
        actions={{}}
      />
    );
  }
}

export default withComparison(TableProducts, {
  withDrawer: false,
  modelType: 'product'
});
