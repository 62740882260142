// @flow
import React, { Fragment } from 'react';
import { FileText } from '@finect/tabular-icons/fileText';
import { AppLink } from '@finect/tabular-core/AppLink';
import { DownloadItem } from './partials';

type Props = {
  contentPiece: Object
};

export const Downloads = ({ contentPiece }: Props) => (
  <Fragment>
    {contentPiece.model.length &&
      contentPiece.model.map(item => (
        <DownloadItem key={item.id}>
          <FileText />
          <AppLink href={item.url} target="_blank" rel="noopener noreferrer">
            {item.description}
          </AppLink>
        </DownloadItem>
      ))}
  </Fragment>
);
