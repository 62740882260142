/* eslint-disable no-inline-comments */
import imported from 'react-imported-component';

// Storefronts
export const Storefront = imported(() =>
  import(/* webpackChunkName: "StorefrontsHome-Index" */ './Storefronts/Storefront'));
export const StorefrontsHome = imported(() =>
  import(/* webpackChunkName: "Storefronts-StorefrontsHome" */ './Storefronts/StorefrontsHome'));
export const StorefrontProductsList = imported(() =>
  import(/* webpackChunkName: "Storefronts-StorefrontProductsList" */ './Storefronts/StorefrontProductsList'));
export const StorefrontArticle = imported(() =>
  import(/* webpackChunkName: "Storefronts-StorefrontArticle" */ './Storefronts/StorefrontArticle'));

export const DigitalHome = imported(() =>
  import(/* webpackChunkName: "Storefronts-DigitalHome" */ './Storefronts/Digital/DigitalHome'));
export const DigitalAcademy = imported(() =>
  import(/* webpackChunkName: "Storefronts-DigitalAcademy" */ './Storefronts/Digital/DigitalAcademy'));

// Productos (todos menos IICs)
export const ProductSheet = imported(() =>
  import(/* webpackChunkName: "Products-ProductSheet" */ './Products/ProductSheet'));
export const WriteReview = imported(() =>
  import(/* webpackChunkName: "Products-WriteReview" */ './Products/WriteReview'));
export const ProductReview = imported(() =>
  import(/* webpackChunkName: "Products-ProductReview" */ './Products/ProductReview'));
export const ProductReviews = imported(() =>
  import(/* webpackChunkName: "Products-ProductReviews" */ './Products/ProductReviews'));

export const ProductQuestions = imported(() =>
  import(/* webpackChunkName: "Products-ProductQuestions" */ './Products/ProductQuestions'));

export const ProductComparison = imported(() =>
  import(/* webpackChunkName: "Products-ProductComparison" */ './Products/ProductComparison'));

export const ProductCheckout = imported(() =>
  import(/* webpackChunkName: "ProductCheckout-ProductCheckout" */ './ProductCheckout/ProductCheckout'));

export const ProductSubscription = imported(() =>
  import(/* webpackChunkName: "ProductCheckout-ProductSubscription" */ './ProductCheckout/ProductSubscription/ProductSubscriptionContainer'));

export const SubscriptionConditions = imported(() =>
  import(/* webpackChunkName: "ProductCheckout-SubscriptionConditions" */ './ProductCheckout/SubscriptionConditions/SubscriptionConditions'));

// Grupos
export const Groups = imported(() => import(/* webpackChunkName: "Groups-Index" */ './Groups/Groups'));

// Onboarding
export const OnBoarding = imported(() => import(/* webpackChunkName: "OnBoarding-Index" */ './OnBoarding/OnBoarding'));
export const Start = imported(() => import(/* webpackChunkName: "OnBoarding-Start" */ './OnBoarding/Start'));

// Articles
export const ArticleScroll = imported(() =>
  import(/* webpackChunkName: "Articles-Article" */ /* webpackPrefetch: true */ './Articles/ArticleScroll'));
export const Latest = imported(() => import(/* webpackChunkName: "Articles-Latest" */ './Articles/Latest'));

// Multimedia
export const MultimediaCommons = imported(() =>
  import(/* webpackChunkName: "Multimedia-Home" */ './Multimedia/MultimediaCommons'));

// Bancos
export const BankArticleList = imported(() =>
  import(/* webpackChunkName: "Banks-BankArticleList" */ './Banks/BankArticleList'));
export const BankArticle = imported(() => import(/* webpackChunkName: "Banks-BankArticle" */ './Banks/BankArticle'));
export const Bank = imported(() => import(/* webpackChunkName: "Banks-Bank" */ './Banks/Bank'));
export const BankProducts = imported(() => import(/* webpackChunkName: "Banks-BankProducts" */ './Banks/BankProducts'));

// Products - Instituciones de inversión colectiva
export const FundSheetById = imported(() => import(/* webpackChunkName: "IIC-FundSheetById" */ './IIC/FundSheetById'));
export const FundSheet = imported(() => import(/* webpackChunkName: "IIC-FundSheet", client-side */ './IIC/FundSheet'));
export const FundsHome = imported(() => import(/* webpackChunkName: "IIC-FundsHome" */ './IIC/Homes/FundsHome'));
export const PlansHome = imported(() => import(/* webpackChunkName: "IIC-PlansHome" */ './IIC/Homes/PlansHome'));
export const ETFsHome = imported(() => import(/* webpackChunkName: "IIC-ETFsHome" */ './IIC/Homes/ETFsHome'));
export const FundsList = imported(() => import(/* webpackChunkName: "IIC-FundsList" */ './IIC/FundsList'));
export const FundsCategories = imported(() => import(/* webpackChunkName: "IIC-FundsCategories" */ './IIC/Categories'));
export const FundsCategory = imported(() => import(/* webpackChunkName: "IIC-FundsCategory" */ './IIC/Category'));
export const FundsManagementCompanies = imported(() =>
  import(/* webpackChunkName: "IIC-FundsManagementCompanies" */ './IIC/ManagementCompanies'));
export const FundsManagementCompany = imported(() =>
  import(/* webpackChunkName: "IIC-FundsManagementCompany" */ './IIC/ManagementCompany'));
export const FundsComparison = imported(() => import(/* webpackChunkName: "IIC-FundsComparison" */ './IIC/Comparison'));

// SuperComparador
export const SuperComparison = imported(() =>
  import(/* webpackChunkName: "SuperComparison" */ './IIC/SuperComparison'));

// Mis Finanzas
export const Panel = imported(() => import(/* webpackChunkName: "MyFinances-Panel" */ './MyFinances/Panel'));
export const Feed = imported(() => import(/* webpackChunkName: "MyFinances-Feed" */ './MyFinances/Feed'));
export const Favorites = imported(() =>
  import(/* webpackChunkName: "MyFinances-Favorites" */ './MyFinances/Favorites'));

export const MyAuthorizations = imported(() =>
  import(/* webpackChunkName: "MyAuthorizations-Index" */ './MyAuthorizations/MyAuthorizations'));

export const MyComparisons = imported(() =>
  import(/* webpackChunkName: "MyComparisons-Index" */ './MyComparisons/MyComparisons'));

export const AdvisersFeedback = imported(() =>
  import(/* webpackChunkName: "AdvisersFeedback" */ './AdvisersFeedback/AdvisersFeedback'));

export const Tag = imported(() => import(/* webpackChunkName: "Tag" */ './Tags/Tag'));

// Tags
export const TagsHome = imported(() => import(/* webpackChunkName: "Tags" */ './Tags/TagsHome'));

// Profiles
export const Group = imported(() => import(/* webpackChunkName: "Profiles-Group" */ './Profiles/Group'));
export const UserProfileLayout = imported(() =>
  import(/* webpackChunkName: "Profiles-User" */ './Profiles/User/UserProfileLayout'));

// Home de Finect
export const Home = imported(() => import(/* webpackChunkName: "Home" */ './Home'));
